.loading-spinner {
  $SPINNER_THICKNESS: 4px;
  $SIZE: 50px;

  border: $SPINNER_THICKNESS solid #f3f3f3;
  border-radius: 50%;
  border-top: $SPINNER_THICKNESS solid rgb(94, 94, 94);
  width: $SIZE;
  height: $SIZE;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
