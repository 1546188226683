// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.wra-link {
  cursor: pointer;
}

.wra-th_avatar {
  width: 48px;
}

/* Dropdown menu for Typeahead */
.wra-typeahead .dropdown-item {
  padding: 0;

  &.disabled {
    padding: 10px 20px;
  }

  & > div {
    padding: 10px 20px;
  }
}

.wra-sales-controls {
  padding: 0.25em 0.25em;
  display: flex;
  & * div {
    margin-right: 8px;
  }
}

.wra-user-avatar {
  width: 160px;
  height: 160px;
  margin: 12px auto;
  display: flex;
}

.wra-sale-user-avatar {
  width: 24px;
  height: 24px;
  margin-right: 0.5em;
  vertical-align: top;
}

.wra-sale-price_divider {
  margin: 0 0.5em;
  width: 2px;
  height: 9px;
  background: #ccc;
  display: inline-block;
}

.wra-sale-status_badge {
  padding: 0.5em 0.75em;
  margin-right: 6px;
}

.wra-sale-status_badge--large-clickable {
  padding: 0.5em 0.75em;
  margin-right: 6px;
  font-size: 1.5em;
  cursor: pointer;
}

.wra-table__vacenter {
  td,
  th {
    vertical-align: middle;
  }
}

.wra-sale-actions {
  & > * {
    margin-right: 6px;
  }
}
